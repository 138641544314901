import Home from "../components/HomePage/home";
import ClientsHome from "../components/Client/clients-home";
import ClientLookup from "../components/Client/client-lookup";
import ClientDetails from "../components/Client/Details/client-details";
import ClientProfile from "../components/Client/Details/client-profile";
import SchoolsTable from "../components/Client/Schools/schools-table";
import SchoolForm from "../components/Client/Schools/school-form";
import ProcessHome from "../components/Process/process-home";
import Notes from "../components/Process/notes";
import GapsInService from "../components/Client/Details/gaps-in-service";
import DocumentLookup from "../components/Process/document-lookup";
import Error from "../components/Security/error";
import Callback from "../components/Security/callback";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: false,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "ClientsHome",
    path: "/clients",
    icon: "users",
    title: "Clients",
    component: ClientsHome,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "ClientLookup",
        path: "/client/lookup",
        icon: "users",
        title: "Client Lookup",
        component: ClientLookup,
        onNavBar: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NewClient",
        path: "/client/new",
        icon: "users",
        title: "New Client",
        component: ClientProfile,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
      {
        name: "ClientDetails",
        path: "/client/details",
        icon: "users",
        title: "Client Chart",
        component: ClientDetails,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Schools",
        path: "/schools",
        icon: "users",
        title: "Schools",
        component: SchoolsTable,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
      {
        name: "NewSchool",
        path: "/schools/new",
        icon: "users",
        title: "New School",
        component: SchoolForm,
        onNavBar: false,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
      {
        name: "SchoolForm",
        path: "/schools/edit",
        icon: "users",
        title: "School Form",
        component: SchoolForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
    ],
  },
  {
    name: "process",
    path: "/process",
    icon: "users",
    title: "Process",
    component: ProcessHome,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      eiAdmin: true,
      sc: true,
    },
    children: [
      {
        name: "notes",
        path: "/process/notes",
        icon: "users",
        title: "Session Note Lookup",
        component: Notes,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
          sc: true,
        },
      },
      {
        name: "GapsInService",
        path: "/process/gaps-in-service",
        icon: "users",
        title: "Gaps in Service Submission",
        component: GapsInService,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
      {
        name: "DocumentLookup",
        path: "/process/documents",
        icon: "users",
        title: "Document Lookup",
        component: DocumentLookup,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          eiAdmin: true,
        },
      },
    ],
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
    onHomePage: false,
  },
];
