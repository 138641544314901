import DistrictAdmin from "./DistrictAdmin";
import School from "./School";
import ClientSchool from "./ClientSchool";
import EIClient from "./EIClient";
import { formatDate, formatPhoneNumber, formatOsis } from "../utils/format-utils";

export default class Client {
    clientId?: number | null;
    legacyClientId?: number | null;
    osis?: string | null;
    gender?: string | null;
    dateOfBirth?: Date | null;
    age?: string | null;
    firstName?: string | null;
    middleInitial?: string | null;
    lastName?: string | null;
    generation?: string | null;
    streetAddress?: string | null;
    streetAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    districtId?: number | null;
    homePhone?: string | null;
    mobilePhone?: string | null;
    workPhone?: string | null;
    preferredPhone?: string | null;
    emailAddress?: string | null;
    alternateEmailAddress?: string | null;
    caregiverFirstName?: string | null;
    caregiverLastName?: string | null;
    caregiverRelationship?: string | null;
    nyeisId?: string | null;
    defaultLanguageId?: number | null;
    referringPhysicianId?: number | null;
    districtAdminId?: number | null;
    districtAdmin?: DistrictAdmin | null;
    currentSchool?: School | null;
    currentSubSchool?: School | null;
    clientSchools?: ClientSchool[] | null;
    eiClient?: EIClient | null;

    public constructor(params: Client = {} as Client) {
        let {
            clientId = 0,
            legacyClientId = 0,
            osis = null,
            gender = null,
            dateOfBirth = null,
            age = null,
            firstName = "",
            middleInitial = null,
            lastName = "",
            generation = null,
            streetAddress = null,
            streetAddressLine2 = null,
            city = null,
            state = null,
            zipCode = null,
            districtId = null,
            homePhone = null,
            mobilePhone = null,
            workPhone = null,
            preferredPhone = null,
            emailAddress = null,
            alternateEmailAddress = null,
            caregiverFirstName = null,
            caregiverLastName = null,
            caregiverRelationship = null,
            nyeisId = null,
            defaultLanguageId = null,
            referringPhysicianId = null,
            districtAdminId = null,
            districtAdmin = null,
            currentSchool = null,
            currentSubSchool = null,
            clientSchools = null,
            eiClient = null
          } = params;
          this.clientId = clientId;
          this.legacyClientId = legacyClientId;
          this.osis = osis;
          this.gender = gender;
          this.dateOfBirth = dateOfBirth && new Date(dateOfBirth);
          this.age = age;
          this.firstName = firstName;
          this.middleInitial = middleInitial;
          this.lastName = lastName;
          this.generation = generation;
          this.streetAddress = streetAddress;
          this.streetAddressLine2 = streetAddressLine2;
          this.city = city;
          this.state = state;
          this.zipCode = zipCode;
          this.districtId = districtId;
          this.homePhone = homePhone && formatPhoneNumber(homePhone);
          this.mobilePhone = mobilePhone && formatPhoneNumber(mobilePhone);
          this.workPhone = workPhone && formatPhoneNumber(workPhone);
          this.preferredPhone = preferredPhone;
          this.emailAddress = emailAddress;
          this.alternateEmailAddress = alternateEmailAddress;
          this.caregiverFirstName = caregiverFirstName;
          this.caregiverLastName = caregiverLastName;
          this.caregiverRelationship = caregiverRelationship;
          this.nyeisId = nyeisId;
          this.defaultLanguageId = defaultLanguageId;
          this.referringPhysicianId = referringPhysicianId;
          this.districtAdminId = districtAdminId;
          this.districtAdmin = districtAdmin && new DistrictAdmin(districtAdmin);
          this.currentSchool = currentSchool && new School(currentSchool);
          this.currentSubSchool = currentSubSchool && new School(currentSubSchool);
          this.clientSchools = clientSchools && clientSchools.map((item) => new ClientSchool(item));
          this.eiClient = eiClient && new EIClient(eiClient);
    }
    get formattedDob() { return formatDate(this.dateOfBirth); }
    get formattedOsis() { return formatOsis(this.osis); }
    get fullName() { return this.firstName + " " + this.lastName; }
}
