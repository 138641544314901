<template>
  <v-container class="mt-10" row fluid>
    <tile-item
      v-for="(resource, index) in resources"
      :key="index"
      :resource="resource"
      class="mr-10"
    ></tile-item>
  </v-container>
</template>

<script>
import { routes } from "../../router/routes";
import TileItem from "./tile-item.vue";
export default {
  components: {
    TileItem,
  },
  data() {
    return {
      routes,
    };
  },
  computed: {
    resources: function () {
      return this.routes.filter((r) => {
        if (r.onHomePage == true) {
          if (r.meta.admin || r.meta.eiAdmin || r.meta.sc) {
            if (r.meta.admin && this.$store.getters["securityModule/isAdmin"]) {
              return true;
            } else if (
              r.meta.eiAdmin &&
              this.$store.getters["securityModule/isEIAdmin"]
            ) {
              return true;
            } else if (
              r.meta.sc &&
              this.$store.getters["securityModule/isServiceCoordinator"]
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
