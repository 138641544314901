<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-card flat>
      <v-row v-if="isEIAdmin && Boolean(client)">
        <v-spacer></v-spacer>
        <v-switch
          v-model="editMode"
          label="Edit Mode"
          dense
          class="mx-4"
        ></v-switch>
      </v-row>
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6"
              ><v-icon left>mdi-account-outline</v-icon> Profile</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <v-row>
              <v-col cols="12" sm="2">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.firstName"
                    label="First Name"
                    :readonly="!editMode"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field
                  v-model="form.middleInitial"
                  label="MI"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.lastName"
                    label="Last Name"
                    :readonly="!editMode"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  v-model="form.gender"
                  :items="genders"
                  label="Gender"
                  :readonly="!editMode"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="pt-4">
                  <date-picker
                    :field="{ label: 'Date Of Birth' }"
                    :value="form.dateOfBirth"
                    :validations="{ required: true, maxDate: new Date() }"
                    :readonly="!editMode"
                    @fieldChange="form.dateOfBirth = $event"
                  >
                  </date-picker>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3"
                ><v-text-field
                  v-model="form.osis"
                  label="OSIS"
                  v-mask="maskOsis"
                  :readonly="!editMode"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2"
                ><v-text-field
                  v-model="form.nyeisId"
                  label="NYEIS ID"
                  type="number"
                  :readonly="!editMode"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  :items="physicians"
                  v-model="form.referringPhysicianId"
                  item-text="displayName"
                  item-value="physicianId"
                  label="Referring Physician"
                  :readonly="!editMode"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  :items="languages"
                  v-model="form.defaultLanguageId"
                  item-text="name"
                  item-value="languageId"
                  label="Default Language"
                  :readonly="!editMode"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="isEiClient"
              label="Is registered in EI"
              hide-details
              :readonly="!editMode"
              @change="handleEIClient"
              class="shrink mr-2 mt-0"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6"
              ><v-icon left>mdi-map-marker-outline</v-icon> Address</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.streetAddress"
                  label="Street Address"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.streetAddressLine2"
                  label="Street Address Line 2"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="form.city"
                  label="City"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  :items="usStates"
                  v-model="form.state"
                  label="State"
                  :readonly="!editMode"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field
                  v-model="form.zipCode"
                  label="ZipCode"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field
                  v-model="form.districtId"
                  label="District"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6"
              ><v-icon left>mdi-phone-outline</v-icon> Contact</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider rules="email" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.emailAddress"
                    label="Email Address"
                    :readonly="!editMode"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider rules="email" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.alternateEmailAddress"
                    label="Alternate Email Address"
                    :readonly="!editMode"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.homePhone"
                  label="Home Phone"
                  v-mask="maskPhone"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.mobilePhone"
                  label="Mobile Phone"
                  v-mask="maskPhone"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="form.workPhone"
                  label="Work Phone"
                  v-mask="maskPhone"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="form.preferredPhone"
                  :items="phoneTypes"
                  label="Preferred Phone"
                  :readonly="!editMode"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="form.caregiverFirstName"
                  label="Caregiver First Name"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="form.caregiverLastName"
                  label="Caregiver Last Name"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="form.caregiverRelationship"
                  :items="caregiverRelationships"
                  label="Caregiver Relationship"
                  :readonly="!editMode"
                ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="isEiClient">
          <v-expansion-panel-header>
            <span class="text-h6"
              ><v-icon left>mdi-chevron-triple-right</v-icon> Early
              Intervention</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <v-row>
              <v-col cols="12" sm="3">
                <div class="pt-4">
                  <date-picker
                    :field="{ label: 'Referral Date' }"
                    :value="form.eiClient.eiReferralDate"
                    :validations="{ maxDate: new Date() }"
                    :readonly="!editMode"
                    @fieldChange="form.eiClient.eiReferralDate = $event"
                  >
                  </date-picker>
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="pt-4">
                  <date-picker
                    :field="{ label: 'Inital Date' }"
                    :value="form.eiClient.eiInitialDate"
                    :validations="{ maxDate: new Date() }"
                    :readonly="!editMode"
                    @fieldChange="form.eiClient.eiInitialDate = $event"
                  >
                  </date-picker>
                </div>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="form.eiClient.caseNumber"
                  label="Case Number"
                  type="number"
                  :readonly="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="form.eiClient.eiStaffingTeam"
                  :items="eiStaffingTeams"
                  label="EI Staffing Team"
                  :readonly="!editMode"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="1">
                <v-checkbox
                  v-model="form.eiClient.active"
                  label="Active"
                  readonly
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions v-if="isEIAdmin">
        <v-spacer></v-spacer>
        <v-btn @click="cancel" text>Cancel</v-btn>
        <v-btn @click="save" :disabled="invalid" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import Client from "../../../objs/Client";
import EIClient from "../../../objs/EIClient";

export default {
  props: ["client"],
  data() {
    return {
      maskPhone: "(###) ###-####",
      maskOsis: "###-###-###",
      editMode: !Boolean(this.client),
      form: new Client(),
      isEiClient: Boolean(this.client != null && this.client.eiClient != null),
      panels: Boolean(this.client) ? [] : [0], // expand Profile panel on create
    };
  },
  computed: {
    isEIAdmin() {
      return this.$store.getters["securityModule/isEIAdmin"];
    },
    genders() {
      return this.$store.state.optionsModule.genders;
    },
    physicians() {
      return this.$store.state.optionsModule.physicians;
    },
    languages() {
      return this.$store.state.optionsModule.languages;
    },
    usStates() {
      return this.$store.state.optionsModule.usStates;
    },
    phoneTypes() {
      return this.$store.state.optionsModule.phoneTypes;
    },
    eiStaffingTeams() {
      return this.$store.state.optionsModule.eiStaffingTeams;
    },
    caregiverRelationships() {
      return this.$store.state.optionsModule.caregiverRelationships;
    },
  },
  created() {
    if (this.client) {
      this.form = { ...this.client };
    } else {
      // if new client, clear selected client from store
      this.$store.dispatch("clientModule/clearClient");
    }
  },
  methods: {
    handleEIClient(value) {
      if (value === true && this.form.eiClient == null) {
        this.form.eiClient = new EIClient();
      }
      if (value === false && this.form.eiClient != null) {
        this.form.eiClient = null;
      }
    },
    cancel() {
      this.$store.dispatch("clientModule/clearClient");
      this.$router.push({ name: "ClientLookup" });
    },
    async save() {
      this.$store.commit("uxModule/setShowLoader", true);
      //format form fields
      this.form.osis = this.form.osis ? this.form.osis.replace(/-/g, "") : null;
      this.form.homePhone = this.form.homePhone
        ? this.form.homePhone.replace(/\D/g, "")
        : null;
      this.form.mobilePhone = this.form.mobilePhone
        ? this.form.mobilePhone.replace(/\D/g, "")
        : null;
      this.form.workPhone = this.form.workPhone
        ? this.form.workPhone.replace(/\D/g, "")
        : null;

      var message;
      // update
      if (this.client) {
        message = await this.$store.dispatch(
          "clientModule/updateClient",
          this.form
        );
        if (message) {
          this.$store.commit("uxModule/setSnackbarMsg", message);
        } else {
          this.$store.commit(
            "uxModule/setSnackbarMsg",
            "Client saved successfully."
          );
        }
      }
      // create
      else {
        message = await this.$store.dispatch(
          "clientModule/createClient",
          this.form
        );
        if (message) {
          this.$store.commit("uxModule/setSnackbarMsg", message);
        } else {
          this.$store.commit(
            "uxModule/setSnackbarMsg",
            "Client saved successfully"
          );
          // go to client details page for new client
          this.$router.push({
            name: "ClientDetails",
            params: {
              clientId: this.$store.state.clientModule.selectedClient.clientId,
            },
          });
        }
      }
      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
